import React from 'react';
// import Style from './Portfolio.module.scss';
// import PortfolioBlock from "./PortfolioBlock";
import {Box, Grid} from "@mui/material";
import {info} from "../../info/Info";
import SocialIcon from "./SocialIcon";
// import classNames from 'classnames';

export default function Portfolio() {
    return (
        <Box>
            <Grid container display={'flex'} justifyContent={'center'} direction={'row'} alignItems={'center'} mt={'3rem'}>
		{/* <span style={{color: info.baseColor}}>
		<Grid item p={2} style={{ fontSize: 40 }}>
		Server Status
		</Grid>
		<Grid item p={4} style={{ fontSize: 24 }}>
		<p>Minecraft: ✔️</p>
		</Grid>
		<Grid item p={4} style={{ fontSize: 24 }}>
		<p>Valheim: ✔️</p>
		</Grid>
		</span>
		<Box display={'flex'} gap={'2.5rem'} justifyContent={'center'} fontSize={{xs: '2rem', md: '2.5rem'}}>
		<Grid item p={4}>
		{info.portfolios.map((portfolio, index) => (
		<button onClick={() => {navigator.clipboard.writeText(portfolio.link);}}>
		<SocialIcon key={index} icon={portfolio.icon} label={portfolio.label} />
		</button>))}
		</Grid>
		</Box> */}
		<Box display={'flex'} gap={'1.5rem'} justifyContent={'center'} fontSize={{xs: '2rem', md: '2.5rem'}}>
               {info.portfolios.map((portfolio, index) => (
                  <SocialIcon key={index} link={portfolio.link} icon={portfolio.icon} label={portfolio.label} />
               ))}
            </Box>
            </Grid>
        </Box>
    );
};
