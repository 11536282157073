import React from 'react';

{/*
function SocialIcon(props) {
    const {link, icon, label} = props;
    return (
        <div style={{color:"rgb(128,51,255)", fontSize:42}}>{label}
	<i className={icon} aria-hidden="true"/>
	</div>
    );
}
*/}
function SocialIcon(props) {
    const {link, icon, label} = props;
    return (
        <div style={{color:"rgb(128,51,255)", fontSize:42}}>{label}
        <a target="_blank" aria-label={label}
           rel="noopener noreferrer" href={link}>
            <i className={icon} aria-hidden="true"/>
        </a>
        </div>
    );
}

export default SocialIcon;
