import self from "../img/self.png"
// import mock1 from "../img/mock1.png"
// import mock2 from "../img/mock2.png"
// import mock3 from "../img/mock3.png"
// import mock4 from "../img/mock4.png"
// import mock5 from "../img/mock5.png"

export let colors = ["rgb(0,255,164)", "rgb(166,104,255)"];

export const info = {
    firstName: "Leland",
    lastName: "Q",
    initials: "lq",
    position: "a SysAdmin and Developer",
    selfPortrait: self,
    gradient: `-webkit-linear-gradient(135deg, ${colors})`,
    baseColor: colors[0],
    miniBio: [ 
        {
            emoji: '☕',
            text: 'fueled by coffee'
        },
        {
            emoji: '🌎',
            text: 'based in the US'
        },
        {
            emoji: "💼",
            text: "Freelance Consultant"
        },
        {
            emoji: "📧",
            text: "phyrexiantk@protonmail.com"
        }
    ],
    socials: [

	{
	    link: "https://discordapp.com/users/373686111865667585",
	    icon: 'fa fa-mobile fa-lg',
	    label: 'discord'
	},
	{
            link: "https://www.snapchat.com/add/lelando502",
            icon: 'fa fa-snapchat-ghost',
            label: 'snapchat'
        },
	{
	    link: "https://steamcommunity.com/profiles/76561197964704967/",
	    icon: "fa fa-steam",
	    label: 'steam'
	}

    ],
    bio: "Associates in Computer Network Administration (LTI). 12+ years corporate experience. Certifications: A+, Net+, MCP, CNA",
    skills:
        {
            proficientWith: ['microsoft', 'linux', 'cloud_infrastructure', 'AD_infrastructure', 'cyber_security', 'programming', 'scripting', 'web_development', 'routing', 'switching'],
            exposedTo: ['OSINT', 'OPSEC']
        }
    ,
    hobbies: [
        {
	    label: 'gaming',
	    emoji: '🎮'
	},
        {
            label: 'movies',
            emoji: '🎥'
        },
	{
	    label: 'drums',
	    emoji: '🥁'
	},
	{
	    label: 'piano',
	    emoji: '🎹'
	}

    ],
    portfolios: [
       // This is where your portfolio projects will be detailed
       //     title: "Project 1",
       //     live: "https://paytonpierce.dev", //this should be a link to the live version of your project, think github pages, netlify, heroku, etc. Or your own domain, if you have it.
       //     source: "https://github.com/paytonjewell", // this should be a link to the **repository** of the project, where the code is hosted.
       //     image: mock1
	{
		link:"https://phyrexian.duckdns.org/map/",
		icon:'fa fa-server fa-lg',
		label:'Minecraft (Dynmap)'
	}
    ]
}
